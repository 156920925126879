const $body = document.querySelector('body');
const $open_button = document.querySelector('#menu-open');
const $close_button = document.querySelector('#menu-close');
const $panel = document.querySelector('.header__panel');
const $menuLinks = document.querySelectorAll('.header__li');

const openMenu = () => {
    if ($screenWidth < 768) {
        $panel.classList.add('header__panel--visible');
        $panel.classList.remove('header__panel--non-visible');
        $body.style.overflow = 'hidden';
        $open_button.setAttribute('aria-expanded', 'true');
        $close_button.setAttribute('aria-expanded', 'true');
    }
}

const closeMenu = () => {
    if ($screenWidth < 768) {
        $panel.classList.remove('header__panel--visible');
        $panel.classList.add('header__panel--non-visible');
        $body.removeAttribute('style');
        $open_button.setAttribute('aria-expanded', 'false');
        $close_button.setAttribute('aria-expanded', 'false');
    }
}

$open_button.addEventListener('click', openMenu);
$close_button.addEventListener('click', closeMenu);
$menuLinks.forEach(element => element.addEventListener('click', closeMenu));